// @flow

import * as React from "react";
import { HashRouter } from "react-router-dom";
import { DiThemeProvider } from "@deepintent/react-component-lib-core";
import { GAEventPublisher } from "@deepintent/react-utils";
import { ID_TO_PATH_REGEX } from "../components/Header/Header";

type Props = {
	history: Object,
	routes: Object,
};

class Root extends React.Component<Props> {
	componentDidMount(): void {
		const { history } = this.props;
		GAEventPublisher.initialize(history, ID_TO_PATH_REGEX);
	}

	render() {
		const { routes } = this.props;
		return (
			<DiThemeProvider>
				<HashRouter>{routes}</HashRouter>
			</DiThemeProvider>
		);
	}
}

export default Root;
