// @flow

import SortOrder from "../enums/SortOrder";

const Constant = {
	RESOURCE_TYPE: "PUBLISHER",
	APPLICATION_NAME: "PUBLISHER_UI",
	GATEWAY_VERSION_MISMATCHED: "GATEWAY_VERSION_MISMATCHED",
	GRACEFUL_API_EVENT: "GRACEFUL_API_EVENT",
	ID_TO_PATH_MAPPING: {
		adtag: "/adtag",
		audience: "/audience",
		deals: "/deals",
		inventory: "/inventory",
		dashboard: "/dashboard",
		creativeLibrary: "/creativeLibrary",
		directAuction: "/directAuction",
		notAuthorized: "/not-authorized",
		publishRx: "/publishRx",
		outcomes: "/outcomes",
	},
	OUTCOMES: {
		MIN_DURATION_IN_DAYS: 30,
		MAX_DURATION_IN_DAYS: 365,
	},
	URLS: {
		LOGIN: "http://www.example.com",
		BEACON_BASE_URL: "//beacon.deepintent.com/",
		BEACON_BASE_URL_FOR_GET_PIXEL: "//useast-beacon.deepintent.com/",
	},
	LOGIN: {
		DI_USER_TOKEN_KEY: "DI_USER_TOKEN",
		ACTIVE_ORG_ID_KEY: "ACTIVE_ORGANIZATION_ID",
		ACTIVE_ADVERTISER_ID_KEY: "ACTIVE_ADVERTISER_ID",
		CLIENT_DETAILS_KEY: "CLIENT_DETAILS",
		CLIENT_ID_KEY: "CLIENT_ID",
		APPLICATION_ENV_KEY: "APPLICATION_ENV",
		DEFAULT_APPLICATION_ENV: "development",
		CODES: {
			UNAUTHENTICATED: "UNAUTHENTICATED",
		},
	},
	LOCALE: {
		DEFAULT_LOCALE: "en",
		DISPLAY_DATE_FORMAT: "MM/DD/YYYY",
		SERVER_DATE_FORMAT: "YYYY-MM-DD",
		DISPLAY_DATE_TIME_FORMAT: "YYYY/MM/DD HH:mm:ss",
		SERVER_DATE_TIME_FORMAT: "YYYY-MM-DD HH:mm:ss",
		DISPLAY_CHART_SUMMARY_FORMAT: "ddd, Do MMM YYYY HH:mm:ss z",
		CURRENCY: "USD",
		IS_UTC: true,
	},
	PLAN: {
		EDIT_PLAN_ROUTE: "audiences/discover",
		LIST_PAGE_SIZE: 50,
		LIST_DEFAULT_OFFSET: 0,
	},
	DISCOVER: {
		DEFAULT_ROUTE: "/audiences/discover",
		STATS: {
			DETAIL_TABLE: {
				LIST_PAGE_SIZE: 20,
				LIST_DEFAULT_OFFSET: 0,
			},
		},
	},
	TARGET_LIST: {
		LIST_PAGE_SIZE: 50,
		LIST_DEFAULT_OFFSET: 0,
	},
	NOTIFICATIONS: {
		NOTIFICATION_LIST_LIMIT: 10,
	},
	CURRENCY: {
		DOLLAR: "$",
	},
	HEADER: {
		DEFAULT_COUNTRY_CODE: "us",
		FLAG_URL: "__PROTOCOL__//storage.googleapis.com/deepintent-cdn/advertiser-ui/assets/__COUNTRY_CODE__.png",
		COOKIE_TTL: 15,
	},
	EVENT_PUBLISHER: {
		EVENTS: {
			APPLICATION_ALERT_MESSAGE_DISPLAY: "application_alert_message_display",
			APPLICATION_ALERT_MESSAGE_RESOLVE: "application_alert_message_resolve",
			FORM_MESSAGES: "form_messages",
			ADVERTISER_CHANGED: "advertiser_changed",
			REFRESH_DASHBOARD: "refresh_dashboard",
		},
	},
	INTERCOM: {
		SETTINGS: {
			hide_default_launcher: true,
			custom_launcher_selector: ".intercom-messenger-trigger-1",
		},
		CDN_LINK: "https://widget.intercom.io/widget/__APP_ID__",
	},
	PLACEHOLDER: {
		PASSBACK_TAG: `Example:
<script src="https://securepubads.g.doubleclick.net/tag/js/gpt.js">
googletag.pubads().definePassback("/network_ID/first_level_ad_unit/second_level_ad_unit", [468, 60]).set("page_url",
"mydomain.com/mypage.html").display();
</script>`,
		CUSTOM_LOGIC_TAG: `var passback = function () {
	// add your passback logic here
};`,
	},

	PRIVACY_POLICY_LINK: "https://www.deepintent.com/privacypolicy",
	TNC_LINK: "https://www.deepintent.com/tnc",
	AD_TAG: {
		PAGE_LIMIT: 50,
		INVENTORY_SELECTION: {
			// To improve the performance we are reducing limit to 100 while fetching the records
			// As now we are firing query for each search text
			PAGE_LIMIT: 99,
		},
		CUSTOM_PARAMS: {
			PAGE_LIMIT: 15,
		},
		DEFAULT_SORT_ORDER: SortOrder.DESC.name,
		DEFAULT_SORT_DIMENSION: "adTagId",
		PASSBACK_TAG: {
			DECLARATION: new RegExp(/^(function)(\s)*\(.*\)\s*\{(.+)\}$/),
			EXPRESSION: new RegExp(
				/^(\n|\s)*(var)(\s)*(\w)+(\s)*=(\s)*(function)(\s)*\(.*\)\s*\{((.|\n|\s)*)\}(\;)*(\n|\s)*$/
			),
			SCRIPT: new RegExp(/^\<script(\s)*(.)*\>(\n|\s)*(.)+(.|\n)*<\/script\>$/),
			DEFAULT_VALUE: `var passback = function () {
	// add you passback logic here
};`,
			PASSBACKTAG_PROPERTY_KEY: {
				SCRIPT_TAG: "SCRIPT_TAG",
				IMAGE: "IMAGE",
				LANDING_URL: "LANDING_URL",
				CUSTOM_CODE: "CUSTOM_CODE",
			},
			ADTAG_PROPERTY_KEY: {
				HEIGHT: "HEIGHT",
				WIDTH: "WIDTH",
				SLOT_ID: "SLOT_ID",
				TAG_TYPE: "TAG_TYPE",
				MACRO: "MACRO",
				INVENTORY: "INVENTORY",
			},
		},
		ANCHOR_TAG_URL: "https://useast-beacon.deepintent.com/email/click?tagId",
		IMG_TAG_URL: "https://prebid.deepintent.com/emailimp?siteId",
		ENEWSLETTER_DOM_OBJ: [
			{
				tagName: "a",
				tagAttributes: [
					{
						attribute: "target",
						attributeValue: "_blank",
					},
					{
						attribute: "rel",
						attributeValue: "nofollow",
					},
					{
						attribute: "data-href",
						attributeValue: "https://useast-beacon.deepintent.com/email/click?tagId=&siteId=",
					},
				],
			},
			{
				tagName: "img",
				tagAttributes: [
					{
						attribute: "data-src",
						attributeValue: "https://prebid.deepintent.com/emailimp?siteId=&tagId=&h=&w=",
					},
				],
			},
		],
	},
	EMPTY_VALUE_PLACEHOLDER: "-",
	DATA_GRID: {
		PAGE_SIZE: 50,
		DEFAULT_PAGE_SIZE: 50,
		ROWS_PER_PAGE_OPTIONS: [15, 25, 50],
	},
	INVENTORY: {
		PAGE_SIZE: 50,
		LIST_DEFAULT_OFFSET: 0,
		SAMPLE_UPLOAD_FILE_LINK: "https://cdn.deepintent.com/samples/sample_inventory_list_v2.csv",
	},
	INVENTORY_LISTING: {
		Inventory_Status_Options: {
			ENABLED: "On",
			DISABLED: "Off",
			ARCHIVED: "Archived",
		},
		Inventory_Type_Options: {
			SITE: "Site",
			APP: "App",
			ENEWSLETTER: "e-Newsletter",
		},
	},
	HEALTHCARE_IDENTITY_MARKUP: `<script type="text/javascript">
	!function (e, t, i, d) {
	if (!e.ditm_dpes) {
		e.ditm_dpes = function (t) {
			e.ditm_dpes.loaded ? t() : e.ditm_dpes.queue.push(t)
		}, e.ditm_dpes.loaded = !1, e.ditm_dpes.queue = [], e.ditm_dpes.version = "1.0";
		var n = t.getElementsByTagName("script")[0], m = t.createElement("script");
		m.async = !1, m.src = "//cdn.deepintent.com/dpes.js", n && n.parentNode
		&& n.parentNode.insertBefore(m, n)
	}
}(window, document);
ditm_dpes(function () {
	ditm_dpes(function () {
		DeepIntent.Dpes.init(config);
	});
});
</script>`,
	HEALTHCARE_DEFAULT_CONFIG: {
		siteId: "",
		type: "locate",
		storage: "localStorage",
		identifiers: [],
		hash: "NONE",
	},
	CREATIVE_LIBRARY: {
		LIST_DEFAULT_OFFSET: 0,
		PAGE_SIZE: 50,
		ADVERTISER_ID_GREATER_THAN: 10145,
		Creative_Deal_Status_Options: {
			APPROVED: "Approved",
			REJECTED: "Rejected",
			PENDING: "Pending",
		},
		Creative_Open_Auction_Status_Options: {
			APPROVED: "Approved",
			REJECTED: "Rejected",
			PENDING: "Pending",
		},
		Creative_Type_Options: {
			BANNER: "Banner",
			VIDEO: "Video",
			NATIVE: "Native",
			AUDIO: "Audio",
		},
		VIDEO_PREVIEW_SRC_URL: "https://storage.googleapis.com/deepintent-cdn/samples/sample_movie.mp4",
		VIDEO_POSTER: "https://storage.googleapis.com/deepintent-cdn/samples/sample_movie_thumbnail.jpg",
	},
	AUDIENCE: {
		DEFAULT_ROUTE: "/audiences/manage/plans",
		PLAN_ROUTE: "/audiences/manage/plans",
		TARGET_LIST_ROUTE: "/audiences/manage/targetlists",
		PAGE_LIMIT: 50,
	},
	AD_TXT_RECORD: "deepintent.com, 1234, DIRECT, 4a5487c769369a8c",
	STATUS_OPTIONS: {
		ENABLED: "On",
		DISABLED: "Off",
		ARCHIVED: "Archived",
	},
	PUBLISHER_ROUTE_HEADLINE: {
		adtag: "Ad Tag",
		audience: "Audience",
		inventory: "Inventory",
		directAuction: "Direct Auction",
		dashboard: "Dashboard",
		publishRx: "Settings",
		creativeLibrary: "Creative Library",
		deals: "Deals",
		outcomes: "Outcomes",
	},
	DEALS: {
		EDIT_TAG: "edit-tag",
		MAP_TAG: "map-tag",
		GET_PIXEL: "get-pixel",
		SENT_ROUTE: "sent",
		RECEIVED_ROUTE: "received",
		CREATE_DEAL: "create",
		EDIT_DEAL: "edit",
		APP_NAME: "PUBLISHER",
		AD_TAG: {
			SUGGESTED_AD_TAGS_PAGE_LIMIT: 50,
			DATA_GRID: {
				ORPHAN_INVENTORIES_PAGE_SIZE: 3,
				MAPPED_AD_TAGS_PAGE_SIZE: 8,
			},
		},
	},
	PREBID_DOCUMENTATION_LINK: "http://prebid.org/dev-docs/bidders.html#deepintent",
	EXCLUSION_LIST: "Exclusion List",
	INCLUSION_LIST: "Inclusion List",
	DATA_DI_ID: "data-di-id",
	FILE_UPLOAD_STATUS: {
		FILE_DEFAULT: "default",
		FILE_UPLOADING: "uploading",
		FILE_UPLOAD_PARTIAL: "partialUpload",
		FILE_UPLOAD_FAILED: "uploadFailed",
		FILE_UPLOAD_SUCCESS: "success",
		FILE_NOT_SUPPORTED: "fileNotSupported",
	},
	MIN_CHARS_FOR_SEARCH: 3,
	TEMP_REDIRECT_URL: "/temp/redirect",
	PUBLISHER_APP_VERSION: "2.2.1",
	INPUT_CHAR_LIMITS: {
		AD_TAG: 255,
		AUDIENCE: 128,
		INVENTORY: 255,
		OUTCOME: 1024,
		RPT_CRITERIA_SET: 256,
	},
	LD_ANONYMOUS_CONTEXT: {
		kind: "multi",
		user: {
			key: "test_user",
		},
		organization: {
			key: "test_org",
		},
	},
};
export default Constant;
